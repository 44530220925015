@font-face {
  font-family: 'EB_Garamond';
  src: local('EB_Garamond'),
    url('./fonts/EB_Garamond/EBGaramond-Italic-VariableFont_wght.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Helvetica_Font_Family';
  src: local('Helvetica_Font_Family'),
    url('./fonts/Helvetica_Font_Family/Helvetica\ 400.ttf') format('truetype');
}

@font-face {
  font-family: 'FUTURA';
  src: local('FUTURA'),
    url('./fonts/FUTURA/Futura Book Italic font.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'CIRCULAR';
  src: local('CIRCULAR'), url('./fonts/CircularStd-Book.ttf') format('truetype');
  font-weight: normal;
}

.main-container2 h2 {
  font-family: 'FUTURA', sans-serif;
}
