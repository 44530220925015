* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.font-face-gm {
  font-family: 'EB_Garamond';
}

li .font-face-link {
  font-family: 'CIRCULAR';
}

.hn .font-face-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-left: 40px;
  margin-right: 20px;
}

.hn .font-face-content h1 {
  font-size: 40px;
  font-weight: 600;
  color: white;
  font-family: 'Helvetica_Font_Family';
  letter-spacing: 0.05em;
}

.hero {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px;
}

@media only screen and (min-width: 480px) {
  .hero {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.collect {
  background-color: black;
  display: flex;
  width: 100%;
  height: 100vh;
}

.logo {
  width: 60px;
}

.ulti {
  width: 100px;
  z-index: 99;
}

.thee {
  height: 450px;
  margin-top: 150px;
  margin-left: 30px;
}

.sdl-text {
  color: #fff;
  margin-top: 180px;
  width: 800px;
  margin-left: 55px;
}

.sdl-text a {
  color: #fff;
}

.sdl-text h5 {
  margin-top: 50px;
}

@media screen and (max-width: 1280px) {
  .thee {
    height: 400px;
    width: 400px;
    margin-top: 160px;
    margin-left: 0px;
  }

  .sdl-text {
    width: 500px;
    font-size: 13px;
    margin-left: 10px;
    margin-top: 150px;
  }

  .sdl-text h5 {
    margin-top: 90px;
  }
}

@media screen and (max-width: 1025px) {
  .thee {
    height: 350px;
    width: 500px;
    margin-left: 50px;
  }

  .sdl-text {
    width: 700px;
    font-size: 12px;
    margin-top: 150px;
  }
  .sdl-text h5 {
    margin-top: 90px;
  }
  .sdl-text h1 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 769px) {
  .thee {
    height: 400px;
    width: 300px;
    margin-left: 0px;
    margin-top: 160px;
  }

  .sdl-text {
    width: 500px;
    height: 400px;
    margin-top: 170px;
  }

  .sdl-text p {
    font-size: 12px;
  }

  .sdl-text h5 {
    margin-top: 0px;
  }
  .sdl-text h1 {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 500px) {
  .thee {
    height: 250px;
    width: 250px;
    margin-top: 110px;
    margin-left: 80px;
  }
  .sdl-text {
    width: 350px;
    height: 50px;
    margin-top: 380px;
    margin-left: 10px;
    position: absolute;
  }

  .sdl-text p {
    font-size: 9px;
  }

  .sdl-text h5 {
    margin-top: 10px;
  }

  .ulti {
    width: 50px;
    z-index: 99;
  }

  .sdl-text h1 {
    margin-left: 0px;
  }
}

.text h1 {
  color: #fff;
  font-family: 'Helvetica_Font_Family';
}

.text p {
  color: #fff;
  font-family: 'Helvetica_Font_Family';
}

nav {
  width: 100%;
  position: absolute;
  top: 0;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

nav .logo {
  width: 60px;
  margin-left: 120px;
  margin-top: 0px;
}

nav .cross {
  width: 40px;
  align-self: flex-end;
  padding-right: 20px;
}

nav h2 a {
  text-decoration: none;
  color: #fff;
}

.hn ul li {
  list-style: none;
  display: inline-block;
  margin-left: 10px;

  padding: 20px;
}

@media only screen and (min-width: 280px) {
  .hn ul li {
    font-size: 2px;
  }
  nav .logo {
    margin-left: 0;
    margin-top: 0;
  }
}

.hn ul li a {
  text-decoration: none;
  color: rgb(232, 233, 233);
  font-size: 15px;
}
.hn {
  margin-top: 150px;
}

.hn ul li :hover {
  border-bottom: 3px solid gray;
  border-radius: 2px;

  font-size: medium;
  cursor: pointer;
}

@media only screen and (min-width: 480px) {
  .hn {
    margin-top: 90px;
  }
}

li .Twitter {
  width: 20px;
}

li .Instagram {
  width: 20px;
}

li .Foundation {
  width: 20px;
}

li .Deviant {
  width: 20px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.loop {
  position: absolute;
  bottom: 0;
}

.loop li {
  list-style: none;
  display: inline-block;
  margin-left: 10px;
  padding: 20px;
}

.back-video {
  position: fixed;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.home {
  width: 100%;
  position: fixed;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.poem {
  position: absolute;
  object-fit: cover;
  z-index: -1;
  display: flex;
  width: 100%;
  height: 100%;
}

.about {
  height: 40vh;
  position: absolute;
  margin-left: 10%;
  margin-top: 10%;
}

.angel {
  width: 80px;
}

.text {
  position: absolute;
  width: 80vh;
  margin-top: 60px;
}

.art {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(12, 3, 51, 0.3), rgba(12, 3, 51, 0.3));

  display: flex;
}

.look {
  width: 60px;
}

.arrange {
  margin-top: 120px;
  margin-left: 50px;
  text-decoration: underline;
}

.item {
  margin-top: 50px;
  font-family: sans-serif;
  display: flex;
  height: 30vh;
}

.item2 {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.container {
  background-color: black;
}

.container h2 {
  padding-top: 120px;
  margin-left: 90px;
  text-decoration: none;
  color: #fff;
}

.image1 {
  object-fit: contain;
}

@media screen and (max-width: 500px) {
  .hn ul li a {
    font-size: 10px;
  }
  .hn .font-face-content h1 {
    text-align: center;
  }
}

.image-container {
  /* height: 500px; */
  padding-top: 30px;
  /* margin-bottom: 100px; */
  /* transform: translatex(30px); */
}

.opid {
  /* width: 600px;
  height: auto; */
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  padding-left: 0px;
  padding-right: 0px;
  /* filter: blur(4px); */
}

.opid2 {
  /* width: 600px;
  height: auto; */
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  padding-left: 0px;
  padding-right: 0px;
  /* filter: blur(4px); */
}

.main-container h2 {
  margin-left: 100px;
  color: #fff;
}

.main-container {
  padding-top: 150px;
  padding-left: 220px;
  padding-right: 250px;
}

.main-container2 {
  padding-top: 150px;
  padding-left: 220px;
  padding-right: 250px;
}

.main-container2 h2 {
  margin-left: 100px;
  color: #fff;
}

.div {
  width: 100%;
  height: 100%;
  background-color: black;
}

.harma {
  background-color: black;
  width: 100%;
  height: 100vh;
}

.harma p {
  font-family: '';
  position: absolute;
  width: 900px;
  margin-top: 180px;
  margin-left: 280px;
  color: #fff;
  font-size: 25px;
  justify-content: center;
  text-align: left;
}

@media screen and (max-width: 500px) {
  .harma p {
    margin-top: 200px;
    margin-left: 50px;
    width: 400px;
    font-size: 15px;
  }

  .hero {
    height: 100vh;
  }
}

@media screen and (max-width: 769px) {
  .harma p {
    margin-top: 230px;
    width: 500px;
    margin-left: 45px;
    font-size: 15px;
  }
}

@media screen and (max-width: 769px) {
  .main-container {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 50px;
  }
  .main-container2 {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 50px;
  }
}
